/* eslint-disable jsx-a11y/alt-text */
import {Box, Container, Grid} from "@mui/material";
import styled from "styled-components";
import Logo from "../../components/Logo";
import Button from '@mui/material/Button';



const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const StyledBox = styled(Box)`
  background: #090A19;
  border-radius: 20px;
  padding: 35px;
  max-width: 700px;
  z-index: 1;
`

const StyledSubtitle = styled.div`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--foreground-rgb);
`

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 400px;
  height: 400px;
  background: radial-gradient(46.47% 46.47% at 50% 50%, rgba(0, 56, 201, 0.192) 0%, rgba(50, 1, 191, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background-blend-mode: screen;
  opacity: 0.3;
`

const StyledText = styled.div`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 36px;
  //color: var(--foreground-rgb);
`


function TokenPanel() {
    return (
        <StyledWrapper>
            <StyledImage src={"/icons/gmd-8.svg"}/>
            <Container sx={{paddingBottom: "100px"}}>
                <Box display={'flex'} justifyContent={"end"}>
                    <StyledBox sx={{minWidth: 275}} component={"div"}>
                        <div>
                            <Logo/>
                        </div>
                        <StyledSubtitle>
                            GMD is the utility and governance token. Accrues 70% of the
                            platform's generated fees through our vaults and reserve.
                        </StyledSubtitle>
                        <Grid container mt={1} spacing={3} justifyContent={"center"}>
                            <Grid item>
                                <Button
                                    href="https://app.uniswap.org/#/swap?outputCurrency=0x4945970EfeEc98D393b4b979b9bE265A3aE28A8B"
                                    variant={"outlined"}
                                    target="blank"
                                    sx={
                                        {
                                            color: "var(--foreground-rgb)",
                                            borderRadius: '100px',
                                            border: "1px solid var(--foreground-rgb)",
                                            padding: "5px 25px",

                                            "&:hover": {
                                                background: "var(--foreground-rgb)",
                                                color: "var(--foreground-black-color)"
                                            }
                                        }
                                    }
                                >

                                    <StyledText>
                                        Buy Uniswap
                                    </StyledText>
                                </Button></Grid>
                            <Grid item>
                                <Button
                                    target="blank"
                                    variant={"outlined"}
                                    sx={
                                        {
                                            color: "var(--foreground-rgb)",
                                            borderRadius: '100px',
                                            border: "1px solid var(--foreground-rgb)",
                                            padding: "5px 25px",

                                            "&:hover": {
                                                background: "var(--foreground-rgb)",
                                                color: "var(--foreground-black-color)"
                                            }
                                        }
                                    }
                                    href="https://traderjoexyz.com/arbitrum/trade?outputCurrency=0x4945970efeec98d393b4b979b9be265a3ae28a8b"
                                >
                                    <StyledText>
                                        Buy Trader Joe
                                    </StyledText>

                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    href={
                                        "https://dexscreener.com/arbitrum/0x7f9a20548d9482041dc33435a7fb25be7c4b98b9"
                                    }
                                    target={"_blank"}

                                    variant={"outlined"}
                                    sx={
                                        {
                                            color: "var(--foreground-rgb)",
                                            borderRadius: '100px',
                                            border: "1px solid var(--foreground-rgb)",
                                            padding: "5px 25px",

                                            "&:hover": {
                                                background: "var(--foreground-rgb)",
                                                color: "var(--foreground-black-color)"
                                            }
                                        }
                                    }
                                >
                                    <StyledText>
                                        Chart
                                    </StyledText>
                                </Button>
                            </Grid>
                        </Grid>


                    </StyledBox>
                </Box>
            </Container>
        </StyledWrapper>
    );
}

export default TokenPanel;
