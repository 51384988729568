import React from 'react';
import {styled} from '@mui/material/styles'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// import Divider from '@mui/material/Divider';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
// import {FaBars} from 'react-icons/fa';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from "./Logo";


// const drawerWidth = 240;


const StyledAppBar = styled(AppBar)`
  background: linear-gradient(180deg, #050713 0%, rgba(5, 7, 19, 0) 100%);
  box-shadow: none;


  @media (max-width: 740px) {
    //z-index: -1;
    background-color: #050713;
  }

`

const StyledToolbar = styled(Toolbar)`
  height: 75px;
  padding: 15px 15px 0 15px;

  @media (max-width: 740px) {
    //z-index: -1;
    padding-bottom: 15px;
  }
`


const EnterAppButton = styled(Button)`
  /* Rectangle 5 */
  height: 54px;
  width: 180px;
  border-radius: 100px;


  box-sizing: border-box;

  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--foreground-rgb);
  

`

const EnterAppText = styled(Typography)`
  font-size: 14px;
  /* identical to box height, or 180% */

  text-align: center;

  color: var(--foreground-rgb);
`


export default function Header(props) {
    const {window} = props;
    // const [mobileOpen, setMobileOpen] = React.useState(false);
    const app_page = "https://app.gmdprotocol.com"
    // const handleDrawerToggle = () => {
    //     setMobileOpen((prevState) => !prevState);
    // };

    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
    //         <Typography variant="h6" sx={{my: 2}}>
    //             <Logo/>
    //         </Typography>
    //         <Divider/>
    //         <List>
    //             <ListItem disablePadding>
    //                 <ListItemButton sx={{textAlign: 'center'}}>
    //                     <a href={app_page}
    //                        target={"_blank"}
    //                        rel="noreferrer">
    //                         <ListItemText primary={"Enter app"}/>
    //                     </a>
    //
    //                 </ListItemButton>
    //             </ListItem>
    //         </List>
    //     </Box>
    // );

    // const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <StyledAppBar component="nav">
                <StyledToolbar>
                    {/*<IconButton*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="open drawer"*/}
                    {/*    edge="start"*/}
                    {/*    onClick={handleDrawerToggle}*/}
                    {/*    sx={{mr: 2, display: {sm: 'none'}}}*/}
                    {/*>*/}
                    {/*    <FaBars/>*/}
                    {/*</IconButton>*/}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{flexGrow: 1, display: {sm: 'block'}}}
                    >
                        <Logo/>
                    </Typography>
                    <Box
                        // sx={{display: {xs: 'none', sm: 'block'}}}
                    >
                        <EnterAppButton href={app_page}
                                        target={"_blank"}
                                        variant={"outlined"}
                                        rel="noreferrer" sx={{color: '#fff'}}>
                            <EnterAppText>
                                Enter app
                            </EnterAppText>
                        </EnterAppButton>
                    </Box>
                </StyledToolbar>
            </StyledAppBar>
            {/*<Box component="nav">*/}
            {/*    <Drawer*/}
            {/*        container={container}*/}
            {/*        variant="temporary"*/}
            {/*        open={mobileOpen}*/}
            {/*        onClose={handleDrawerToggle}*/}
            {/*        ModalProps={{*/}
            {/*            keepMounted: true, // Better open performance on mobile.*/}
            {/*        }}*/}
            {/*        sx={{*/}
            {/*            display: {xs: 'block', sm: 'none'},*/}
            {/*            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {drawer}*/}
            {/*    </Drawer>*/}
            {/*</Box>*/}
        </Box>
    );
}
