import styled from "styled-components";
import {Container, Box, Grid} from "@mui/material";
import useTokenInfo from "../../hooks/useTokenInfo";

const StyledWrapper = styled.div`
  //position: relative;

  margin-top: 100px;
  margin-bottom: 100px;
  background: #090A19;
  padding-top: 20px;
  padding-bottom: 40px;

`


const StyledCircleWrapper = styled.div`
  position: relative;
  height: 100%;

  @media (max-width: 900px){
    margin-top: 50px;
    height: 400px;
    width: auto;
  }
  
`

const StyledCircle = styled.img`
  width: 700px;
  height: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


  @media (max-width: 900px){
    width: 525px;
    height: 525px;
  }
`

const StyledCircleRotate = styled.img`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  
  animation: spin 10s linear infinite;
  

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  @media (max-width: 900px){
    width: 150px;
    height: 150px;
  }
`

const CoinBox = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #131539 0%, rgba(19, 21, 57, 0.3) 100%);
  height: 100px;
  border-radius: 12px;
  max-width: 360px;

  &:nth-child(odd) {
    margin-left: auto;
    margin-top: 20px;
  }

  &:nth-child(even) {
    margin-right: auto;
    margin-top: 20px;
  }

  @media (max-width: 1040px) {
    background: linear-gradient(90deg, #131539 0%, rgba(19, 21, 57, 0.7) 100%);
  }

`
const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 25px;
`

const StyledText = styled.div`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #FFFFFF;
`

const CoinSection = () => {
    const { pool2, fetchData2 } = useTokenInfo();
    return (
        <StyledWrapper>
            <Container>
                <Box>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12} >
                            {/*<div>*/}
                            <CoinBox>
                                <StyledImage src="/icons/usdc.svg" alt={""}/>
                                <StyledText>
                                    {pool2[0].apr}%
                                </StyledText>
                            </CoinBox>
                            <CoinBox>
                                <StyledImage src="/icons/bitcoin.svg" alt={""}/>
                                <StyledText>
                                    {pool2[2].apr}%
                                </StyledText>
                            </CoinBox>
                            <CoinBox>
                                <StyledImage src="/icons/eth.svg" alt={""}/>
                                <StyledText>
                                    {pool2[1].apr}%
                                </StyledText>
                            </CoinBox>
                            {/*</div>*/}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <StyledCircleWrapper>
                                <StyledCircle src="/icons/circle-new.svg" alt=""/>
                                <StyledCircleRotate src="/icons/gmd-7.svg" alt=""/>
                                {/*<StyledCircleRotate src="/icons/gmd-7.svg" alt=""/>*/}
                            </StyledCircleWrapper>

                        </Grid>
                    </Grid>

                </Box>


            </Container>

        </StyledWrapper>
    )
}

export default CoinSection;