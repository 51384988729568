/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components";
import {Box} from "@mui/material";

import Container from '@mui/material/Container';


const Socials = styled(Box)`
  display: flex;
  //justify-content: space-between;
  align-items: center; 
  width: 100%;
  max-width: 420px;

  > a:hover {
    opacity: 0.9;

    > img {
      filter: brightness(0) invert(1);
    }
  }
`;

const StyledLogoWrapper = styled.div`
  display: flex; 
  align-items: center;
  margin-right: 25px;
  
`

const LogoTextSVG = styled(Box)`
  background: url("/logo.png");
  background-size: 100% 100%;
  width: 50px;
  height: 60px;
`;

const StyledFooter = styled(Box)`

  height: 120px;
  border-top: 1px solid #282b4c;
  background: #090A19;

`;

const StyledBox = styled(Box)`
  height: 100%;
  display: flex;
`

const StyledSocial = styled.a`
  margin-right: 35px;
`

const Footer = () => {
    const socials = [
        {
            element: "/icons/twitter.svg",
            link: "https://twitter.com/GMDprotocol",
        },
        {
            element: "/icons/medium.svg",
            link: "https://medium.com/@gmdprotocol",
        },

        {
            element: "/icons/github.svg",
            link: "https://github.com/saulgoodmandev/gmd",
        },
        {
            element: "/icons/telegram.svg",
            link: "https://t.me/+BhDiy07pXDJjMGE5",
        },
        {
            element: "/icons/discord.svg",
            link: "https://discord.gg/x649QhTYxs",
        },
    ];

    return (
        <StyledFooter>
            <Container sx={{height: "100%"}}>
                <StyledBox>
                    <StyledLogoWrapper>
                        <LogoTextSVG/>
                    </StyledLogoWrapper>
                    <Box component="div" sx={{flexGrow: 1}}/>

                    <Socials>
                            {socials.map((data, i) => {
                                return (
                                    <StyledSocial href={data.link} target={"_blank"}
                                                  rel="noreferrer">
                                        <img src={data.element} alt={""}/>
                                    </StyledSocial>
                                );
                            })}
                    </Socials>
                </StyledBox>
            </Container>
        </StyledFooter>
    );
};


export default Footer;
