export const MULTICALL_ADDR = "0x842eC2c7D803033Edf55E478F461FC547Bc54EB2";
export const VAULT_ADDR = "0x8080B5cE6dfb49a6B86370d6982B3e2A86FBBb08";
export const PRICE_ADDR = "0x06e31Ad70174D7dB1Eb06fCDa75Aa254E311BC3f";
export const ETH_ADDR = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
export const BTC_ADDR = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
export const USDC_ADDR = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
export const GDlptoken = [
  "0x4A723DE8aF2be96292dA3F824a96bfA053d4aF66",
  "0xc5182E92bf001baE7049c4496caD96662Db1A186",
  "0xEffaE8eB4cA7db99e954adc060B736Db78928467",
];
export const MINT_ADDR = "0x4945970EfeEc98D393b4b979b9bE265A3aE28A8B";
export const GMD_STAKING_ADDR = "0x48C81451D1FDdecA84b47ff86F91708fa5c32e93";
export const GMD_STAKING_ADDR2 = "0x48C81451D1FDdecA84b47ff86F91708fa5c32e93";
export const ESGMD_ADDR = "0x49e050df648e9477c7545fe1779b940f879b787a"
export const GMD_ADDR = "0x4945970EfeEc98D393b4b979b9bE265A3aE28A8B";
export const NEWVAULT_ADDR = "0x8080B5cE6dfb49a6B86370d6982B3e2A86FBBb08";
export const USDT_ADDR = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";
export const ETH_VAULT_V2 = "0x5F01D28467953fDa3dc0e2828DAEAbDa0b06CDF3";
export const BTC_VAULT_V2 = "0xB0F3e3F5C52Ece66Fe44ac7ECedbD06B7aEc11b2";
