import {createTheme} from '@mui/material/styles'


export const getTheme = () => {
    return createTheme({
        palette: {
            background: {
                paper: "#101227",
                default: "#101227", // Replace with your default background color
            },
            text: {
                primary: "#EEF9FF",
            }
        },
    })
}
