import { BrowserRouter } from "react-router-dom";

import Home from "./pages/Home";
import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer";

import "./App.css";
import Notification from "./components/Notification";

import AppThemeProvider from "./providers/theme/provider";
import DefaultLayout from "./components/DefaultLayout";

function App() {
  return (
    // <BrowserRouter>
    //   <TopBar />
    //   <Home />
    //   <Footer />
    //   <Notification />
    // </BrowserRouter>
    <AppThemeProvider>
      <DefaultLayout>
        <Home />
        <Notification />
      </DefaultLayout>
    </AppThemeProvider>
  );
}

export default App;
