/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import "./menu.css";
import { Box } from "@mui/material";
import styled from "styled-components";

const Hamburger = ({ activePage, setActivePage }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mouseup", function (event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        let form = document.getElementById("check");
        if (form) form.checked = false;
      }
    });
  }, []);

  const menus = [
    "Dashboard",
    "BFR Vault",
    "GMX Vault",
    "Stake",
    "Provide Liquidity",
  ];
  const app_page = "https://app.gmdprotocol.com";
  return (
    <nav role="navigation" style={{ background: "red" }}>
      <div id="menuToggle" ref={menuRef}>
        {/* A fake / hidden checkbox is used as click reciever,
    so you can use the :checked selector on it. */}

        <input type="checkbox" id="check" />

        {/* Some spans to act as a hamburger.
    
    They are acting like a real hamburger,
    not that McDonalds stuff. */}

        <span></span>
        <span></span>
        <span></span>

        {/* Too bad the menu has to be inside of the button
    but hey, it's pure CSS magic. */}
        <Menu id="menu">
          <StyledContainer>
            <Box
              display={"flex"}
              minWidth={"70px"}
              minHeight={"70px"}
              maxWidth={"70px"}
              maxHeight={"70px"}
            ></Box>
          </StyledContainer>
          <Box>
            <Menus active={activePage}>
              {menus.map((data, i) => {
                return (
                  <MenuItem>
                    <a
                      key={i}
                      href={`${app_page}/${data
                        .replace(" ", "")
                        .toLowerCase()}`}
                      onClick={() => setActivePage(i + 1)}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {data}
                    </a>
                  </MenuItem>
                );
              })}
              <Dbox>
                <a
                  href={`https://gmd-protocol.gitbook.io/gmd-protocol/`}
                  target="blank"
                >
                  Docs
                </a>
              </Dbox>
              <Dbox>
                <a href={`https://stats.gmdprotocol.com`} target="blank">
                  Stats
                </a>
              </Dbox>
              {/* 
              <Dbox>
                <a
                      
                      href={`http://twitter.com/intent/tweet?text=This%20is%20my%20tweet%20to%20acquire%20early%20access%20to%20@GMDprotocol%20pseudo-delta-neutral%20USDC,ETH,and%20BTC%20vaults%20built%20on%20top%20of%20$GLP%20and%20mint%20$GMD,%20the%20protocol's%20governance%20and%20real%20yield%20token.%20visit%3A&url=http%3A%2F%2Fgmdprotocol.com%20for%20more%20information.`}
                      target ="blank"
                    >
                      Whitelist
                </a>
              </Dbox> */}
            </Menus>
          </Box>
        </Menu>
      </div>
    </nav>
  );
};

const MenuItem = styled(Box)`
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #a0a3c4 !important;
  transition: unset;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
  :hover {
    background: rgba(48, 63, 208, 0.1411764706);
    color: white !important;
  }
  transition: unset !important;
`;

const Menus = styled(Box)`
  > a:nth-child(${({ active }) => active}) {
    background: rgba(48, 63, 208, 0.1411764706);
    color: white !important;
  }
`;

const Dbox = styled(Box)`
  display: flex;
  > a {
    padding: 18px 17px;
    display: block;
    color: #a0a3c4;
    font-size: 15px;
    line-height: 18px;
    font-weight: normal;
    background: rgba(48, 63, 208, 0.1411764706);
  }
`;

const StyledContainer = styled(Box)``;

const Menu = styled.ul`
  position: relative;
  overflow: hidden;
`;

export default Hamburger;
