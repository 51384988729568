/* eslint-disable jsx-a11y/alt-text */
import { Box, Container, Grid, Card, Typography, CardContent } from "@mui/material";
import styled from "styled-components";


const StyledCard = styled(Card)`
  max-width: 469px;
  display: flex;
  align-items: center;
  background: #090A19 !important;
  border-radius: 20px !important;
  padding: 10px 15px;
  height: 175px;

  @media (max-width: 1360px) {
    margin-right: 30px;
    margin-bottom: 20px;
  }
  @media (max-width: 850px) {
    margin-right: 0;
  }
`

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const StyledTitle = styled.div`
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 129% */
  
  color: var(--foreground-rgb);
`

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 25px;
`

const StyledSubtitle = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: var(--foreground-rgb);
`

function InfoPanel() {
    const infos = [
        {
            title: "Single stake vaults",
            url: "/icons/pc.svg",
            value:
                "Single stake your USDC, ETH, BTC earning aggregated yields from $GLP",
            width: 387
        },
        {
            title: "Zero Token Emissions",
            url: "/icons/star.svg",
            value:
                "$GMD tokens have a fixed supply and cannot be further minted",
            width: 434,
        },
        {
            title: "Real Yield",
            url: "/icons/up.svg",
            value:
                "Stake our governance and uitility token: $GMD to earn platform revenue through our vaults and reserve",
            width: 469
        },
    ];

    return (

        // <Container sx={{paddingTop: 5}}>
        //     <Box>
        //         <Grid container
        //               justifyContent="space-between" spacing={{xs: 1, md: 2}} columns={{xs: 1, sm: 1, md: 3}}
        //               direction="row">
        //             {infos.map((data, i) => {
        //                 return (
        //                     <Grid item xs={1} sm={1} md={1}>
        //                         <StyledCard sx={{minWidth: 275}}>
        //                             <CardContent>
        //                                 <StyledTitleWrapper component="div">
        //                                     <StyledImage src={data.url} alt={""}/>
        //                                     <StyledTitle>
        //                                         {data.title}
        //                                     </StyledTitle>
        //                                 </StyledTitleWrapper>
        //                                 <StyledSubtitle variant="body2">
        //                                     {data.value}
        //                                 </StyledSubtitle>
        //                             </CardContent>
        //                         </StyledCard>
        //                     </Grid>
        //                 );
        //             })}
        //         </Grid>
        //     </Box>
        // </Container>
        <div className="tw-max-w-[1440px] tw-w-full tw-mx-auto tw-px-7 tw-flex tw-items-center tw-justify-between tw-flex-wrap laptop1:tw-justify-center">
            {infos.map((data, i) => {
                return (
                    <StyledCard sx={{ minWidth: 275, width: data.width }} >
                        <CardContent>
                            <StyledTitleWrapper component="div">
                                <StyledImage src={data.url} alt={""} />
                                <StyledTitle>
                                    {data.title}
                                </StyledTitle>
                            </StyledTitleWrapper>
                            <StyledSubtitle variant="body2">
                                {data.value}
                            </StyledSubtitle>
                        </CardContent>
                    </StyledCard>
                );
            })}
        </div>
    );
}


export default InfoPanel;
