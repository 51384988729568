/* eslint-disable jsx-a11y/alt-text */
import {Box, Button, Grid, Container} from "@mui/material";
import styled from "styled-components";

const StyledButton = styled(Button)`
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700 !important;
  /* font-size: 20px !important; */
  /* line-height: 36px !important; */
  /* identical to box height, or 180% */

  text-align: center;

  color: #EEF9FF;
  height: 60px;
  width: 200px;
  border-radius: 100px;

  background: #357AE4;

  @media (max-width: 740px) {
    height: 40px;
    width: 150px;
  }

  &:hover {
    background: var(--foreground-rgb);
  }
`

const StyledTitle = styled(Box)`
  background: linear-gradient(274.43deg, #EEF9FF 1.55%, #AAE5FF 69.64%, #357AE4 105.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 100px;

  max-width: 650px;


  @media (max-width: 500px) {
    font-size: 43.4px;
    font-weight: 700;
    margin-bottom: 15px;
    letter-spacing: 0;
  }
  @media (max-width: 350px) {
    font-size: 38.75px;
    font-weight: 700;
    margin-bottom: 15px;
    letter-spacing: 0;
  }
`;

const StyledSubtitle = styled(Box)`
  color: var(--foreground-rgb);
  font-weight: 500;
  font-size: 20px;
  max-width: 650px;
  padding-bottom: 25px;


  @media (max-width: 400px) {
    font-size: 15px;
    max-width: 444px;
    color: var(--foreground-rgb);
    margin-bottom: 26px;
  }
`;

const StyledWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  padding-bottom: 64px;
  padding-top: 35px;

  > div {
    width: 100%;
    max-width: 1264px;

    @media (max-width: 600px) {
      padding-left: 22px;
      padding-right: 22px;
    }
    @media (max-width: 500px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
`;

const GMDImage = styled.img`
  z-index: 0;
  position: absolute;
  width: 680px;
  height: 680px;
  right: -170px;
  bottom: -310px;
`

function Index() {

    return (
        <StyledWrapper style={{margin: " 0 auto"}}>
            <GMDImage src={'/icons/gmd-7.svg'}/>
            <Container>
                <Box>
                    <div style={{position: "relative"}}>

                        <StyledTitle>Yield Aggregator & Smart Vaults</StyledTitle>
                        <StyledSubtitle>
                            Stake your USDC, ETH, and BTC and earn up to 15% apy.
                            Pseudo-delta-neutral strategies and smart vaults built on top of GMX.
                        </StyledSubtitle>
                    </div>
                    <Box sx={{maxWidth: "500px"}} display="flex" justifyContent="flex-start" spacing={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6}>
                                <StyledButton
                                    href={"https://app.gmdprotocol.com"}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    sx={{
                                        borderRadius: '100px',
                                        "&:hover": {
                                            background: "var(--foreground-rgb)",
                                            color: "var(--foreground-black-color)"
                                        }
                                    }}
                                    variant="contained"
                                >
                                    Enter App
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <StyledButton
                                    href={"https://gmd-protocol.gitbook.io/gmd-protocol/"}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    sx={{
                                        borderRadius: '100px',
                                        "&:hover": {
                                            background: "var(--foreground-rgb)",
                                            color: "var(--foreground-black-color)"
                                        }
                                    }}
                                    variant="contained"
                                >
                                    Read more
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Container>

        </StyledWrapper>
    );
}

export default Index;
