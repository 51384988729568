import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles'


import {getTheme} from './theme'


const AppThemeProvider = ({children}) => {
    const theme = getTheme()

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </MuiThemeProvider>
    )
}

export default AppThemeProvider;
