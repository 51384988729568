import { styled } from '@mui/material/styles'
import {Paper} from '@mui/material'
import Header from "./Header";
import Footer from "./Footer";
import Notification from "./Notification";


const StyledPaper = styled(Paper)`
  //display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, var(--background-start-rgb) 0%, var(--background-end-rgb) 100%);
`

const StyledBox = styled('div')`
  width: 100%;
  height: 100%;
  min-height: 75vh; 

  ${({theme}) => theme.breakpoints.up('xs')} {
    padding: 74px 0 0 0;
  }

   ${({theme}) => theme.breakpoints.up('md')} {
     padding: 90px 0 0 0;
   }
`

const DefaultLayout = ({children}) => {
    return (
        <StyledPaper>
            <Header/>
            <StyledBox>
                {children}
            </StyledBox>
            <Footer/>
            <Notification />
        </StyledPaper>
    )
}

export default DefaultLayout;