/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import HeroSection from "./HeroSection";
import StatsSection from "./StatsSection";
import InfoPanel from "./InfoPanel";
import CoinSection from "./CoinSection";
import TokenPanel from "./TokenPanel";

const StyledWrapper = styled.div`
  background: linear-gradient(180deg, #101227 0%, #121538 100%);
`;

function Home() {
  return (
    <StyledContainer>
      <HeroSection />
      <StyledWrapper>
        <StatsSection />
        <InfoPanel />
        <CoinSection />
        <TokenPanel />
      </StyledWrapper>
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)`
  color: white;
`;

export default Home;
