/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { multicall } from "../utils/contracts";
import PriceABI from "../abis/PriceABI.json";
import ValutABI from "../abis/ValutABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import GMDStakingABI from "../abis/GMDStakingABI.json";
import NewVaultABI from "../abis/NewVaultABI.json";
import ETHVaultV2ABI from "../abis/ETHVaultV2ABI.json";
import BTCVaultV2ABI from "../abis/BTCVaultV2ABI.json";
import { Token } from "@uniswap/sdk-core";
import IUniswapV3Pool from "../abis/IUniswapV3Pool.json";
import axios from "axios";
import _ from "lodash";

import { Pool } from "@uniswap/v3-sdk/";
import { ethers } from "ethers";
import {
  BTC_ADDR,
  ETH_ADDR,
  PRICE_ADDR,
  USDC_ADDR,
  VAULT_ADDR,
  GMD_STAKING_ADDR,
  GMD_STAKING_ADDR2,
  GMD_ADDR,
  NEWVAULT_ADDR,
  USDT_ADDR,
  ESGMD_ADDR,
  ETH_VAULT_V2,
  BTC_VAULT_V2
} from "../abis/address";

export const GMD_ID = "gmd-protocol";

const defaultVal = {
  fetchData: () => {},
  fetchPrice: () => {},
  totalUSDValuts: 0,
  GLPinVault: 0,
  GLPPrice: 0,
  totalFees: 0,
  GLPbackingNeeded: 0,
  price: 0,
  liquidity: 0,
  tvl: 0,
  ethPrice: 0,
  revenue: 0,
  totalGmdStaked: 0,
  totalESGmdStaked: 0,
  pool: [{}, {}, {}],
};

export const TokenInfoContext = React.createContext(defaultVal);

export const getValueOfTreasury = async (treasuryAddr) => {
  const res = await axios.get(
    `https://api.covalenthq.com/v1/42161/address/${treasuryAddr}/balances_v2/?quote-currency=USD&format=JSON&nft=false&no-nft-fetch=false&key=ckey_6cd616c30ff1407bbbb4b12c5bd`
  );
  const val = res.data.data.items;
  let price = 0;
  _.map(val, (each) => {
    if (each.contract_address === GMD_ADDR) {
      price +=
        (parseInt(each.balance) / Math.pow(10, each.contract_decimals)) *
        each.quote_rate;
    }
    price += each.quote;
  });
  console.log(price);
  return price;
};

/**
 * Formula source: http://www.linked8.com/blog/158-apy-to-apr-and-apr-to-apy-calculation-methodologies
 *
 * @param interest {Number} APR as percentage (ie. 5.82)
 * @param frequency {Number} Compounding frequency (times a year)
 * @returns {Number} APY as percentage (ie. 6 for APR of 5.82%)
 */
const SECONDS_PER_YEAR = 365.25 * 24 * 60 * 60;
const BLOCKS_IN_A_YEAR = SECONDS_PER_YEAR / 14;
const aprToApy = (interest, frequency = BLOCKS_IN_A_YEAR) =>
  ((1 + interest / 100 / frequency) ** frequency - 1) * 100;

export default function useTokenInfo() {
  return React.useContext(TokenInfoContext);
}
let dataid = null;

// pool address for DAI/USDC 0.05%

export function TokenInfoProvider({ children }) {
  const [totalUSDValuts, setTotalUSDValuts] = useState(0);
  const [totalGmdStaked, setTotalgmdStaked] = useState(0);
  const [totalESGmdStaked, setTotalESGmdStaked] = useState(0);
  const [ethPrice, setETHprice] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [price, setPrice] = useState(0);
  const [GLPinVault, setGLPInValut] = useState(0);
  const [GLPPrice, setGLPPrice] = useState(0);
  const [tvl, setTVL] = useState(0);
  const [GLPinVault2, setGLPInValut2] = useState(0);
  const [GLPbackingNeeded2, setGLPBackingNeeded2] = useState(0);
  const [totalUSDValuts2, setTotalUSDValuts2] = useState(0);
  const [treasury, setTreasury] = useState(0);
  const [v2ETHTVL, setV2ETHTVL] = useState(0);
  const [v2BTCTVL, setV2BTCTVL] = useState(0);

  const [pool2, setPool2] = useState([
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
      depositFee: 0,
    },
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
      depositFee: 0,
    },
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
      depositFee: 0,
    },
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
      depositFee: 0,
    },
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
      depositFee: 0,
    },
  ]);

  async function fetchData() {
    try {
      let calls = [
        { address: VAULT_ADDR, params: [0], name: "poolInfo" },
        { address: VAULT_ADDR, params: [1], name: "poolInfo" },
        { address: VAULT_ADDR, params: [2], name: "poolInfo" },
      ];
      const result = await multicall(ValutABI, calls);

      let calls2 = [
        {
          address: VAULT_ADDR,
          params: [],
          name: "GLPinVault",
        },
      ];

      const result4 = await multicall(ValutABI, calls2);
      const _glpinvault = result4 ? result4[0] / Math.pow(10, 18) : 0;
      setGLPInValut(_glpinvault);
      console.log(_glpinvault);

      calls = [
        { address: PRICE_ADDR, params: [USDC_ADDR], name: "getPrice" },
        { address: PRICE_ADDR, params: [ETH_ADDR], name: "getPrice" },
        { address: PRICE_ADDR, params: [BTC_ADDR], name: "getPrice" },
      ];
      const _prices = await multicall(PriceABI, calls);

      calls2 = [{ address: PRICE_ADDR, params: [], name: "getGLPprice" }];

      const _prices2 = await multicall(PriceABI, calls2);
      const _glpprice = _prices2 ? _prices2[0][0] / Math.pow(10, 12) : 0;
      setGLPPrice(_glpprice);
      console.log(_glpprice);
      calls = [
        {
          address: GMD_STAKING_ADDR,
          name: "totalWETHdistributed",
          params: [],
        },
        {
          address: GMD_STAKING_ADDR2,
          name: "totalWETHdistributed",
          params: [],
        },
      ];
      const result2 = await multicall(GMDStakingABI, calls);
      calls = [
        {
          address: GMD_ADDR,
          name: "balanceOf",
          params: [GMD_STAKING_ADDR2],
        },
        {
          address: ESGMD_ADDR,
          name: "balanceOf",
          params: [GMD_STAKING_ADDR2],
        },
      ];
      const result3 = await multicall(ERC20ABI, calls);

      const USDCSAmount =
        (result[0].totalStaked * _prices[0][0]) / Math.pow(10, 48);
      const ETHSAmount =
        (result[1].totalStaked * _prices[1][0]) / Math.pow(10, 48);
      const BTCSAmount =
        (result[2].totalStaked * _prices[2][0]) / Math.pow(10, 48);
      let _totalUSDValuts = USDCSAmount + ETHSAmount + BTCSAmount;
      let _ethPrice = _prices[1][0] / Math.pow(10, 30);
      let _revenue = result2[0] / Math.pow(10, 18);
      let _revenue2 = result2[1] / Math.pow(10, 18);
      let _gmdstaked = result3[0] / Math.pow(10, 18);
      let _esgmdstaked = result3[1] / Math.pow(10, 18);
      console.log(_gmdstaked);
      setTotalUSDValuts(_totalUSDValuts);
      setETHprice(_ethPrice);
      setRevenue(_revenue2 + 197);
      setTotalgmdStaked(_gmdstaked);
      setTotalESGmdStaked(_esgmdstaked);
      console.log('gmd', _gmdstaked);
      setTVL(_glpinvault * _glpprice ** Math.pow(10, 6) + _gmdstaked * 24);
      calls = [
        {
          address: ETH_VAULT_V2, 
          name: "totalUSDvault",
          params: []
        },
      ];
      const res1 = await multicall(ETHVaultV2ABI, calls);
      setV2ETHTVL(Number(res1[0][0]) / Math.pow(10, 8));
      calls = [
        {
          address: BTC_VAULT_V2, 
          name: "totalUSDvault",
          params: []
        },
      ];
      const res2 = await multicall(BTCVaultV2ABI, calls);
      setV2BTCTVL(Number(res2[0][0]) / Math.pow(10, 8));
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchData2() {
    try {
      let calls = [
        {
          address: NEWVAULT_ADDR,
          params: [],
          name: "GLPinVault",
        },
        {
          address: NEWVAULT_ADDR,
          params: [],
          name: "GLPbackingNeeded",
        },

        { address: NEWVAULT_ADDR, params: [0], name: "GDpriceToStakedtoken" },
        { address: NEWVAULT_ADDR, params: [1], name: "GDpriceToStakedtoken" },
        { address: NEWVAULT_ADDR, params: [2], name: "GDpriceToStakedtoken" },
        { address: NEWVAULT_ADDR, params: [4], name: "GDpriceToStakedtoken" },

        {
          address: NEWVAULT_ADDR,
          params: [],
          name: "totalUSDvaults",
        },
      ];
      const result = await multicall(NewVaultABI, calls);
      console.log(result);
      setGLPInValut2(result ? result[0] / Math.pow(10, 18) : 0);
      setGLPBackingNeeded2(result ? result[1] / Math.pow(10, 18) : 0);

      let calls2 = [
        { address: NEWVAULT_ADDR, params: [0], name: "poolInfo" },
        { address: NEWVAULT_ADDR, params: [1], name: "poolInfo" },
        { address: NEWVAULT_ADDR, params: [2], name: "poolInfo" },
        { address: NEWVAULT_ADDR, params: [4], name: "poolInfo" },
      ];

      const poolInfomation = await multicall(NewVaultABI, calls2);
      console.log(poolInfomation);
      calls = [
        { address: PRICE_ADDR, params: [], name: "getGLPprice" },
        { address: PRICE_ADDR, params: [USDC_ADDR], name: "getPrice" },
        { address: PRICE_ADDR, params: [ETH_ADDR], name: "getPrice" },
        { address: PRICE_ADDR, params: [BTC_ADDR], name: "getPrice" },
        { address: PRICE_ADDR, params: [USDT_ADDR], name: "getPrice" },
      ];
      const _prices = await multicall(PriceABI, calls);
      let USDCSAmount = 0;
      let ETHSAmount = 0;
      let BTCSAmount = 0;
      let USDTAmount = 0;

      if (_prices) {
        setGLPPrice(_prices[0][0] / Math.pow(10, 18));
        USDCSAmount =
          (poolInfomation[0].totalStaked * _prices[1][0]) / Math.pow(10, 48);
        ETHSAmount =
          (poolInfomation[1].totalStaked * _prices[2][0]) / Math.pow(10, 48);

        BTCSAmount =
          (poolInfomation[2].totalStaked * _prices[3][0]) / Math.pow(10, 48);
        USDTAmount =
          (poolInfomation[3].totalStaked * _prices[4][0]) / Math.pow(10, 36);
      }

      let _totalUSDValuts = result ? result[6] / Math.pow(10, 18) : 0;
      console.log(_totalUSDValuts);
      setTotalUSDValuts2(_totalUSDValuts);

      setTreasury(
        getValueOfTreasury("0x4bf7a0c21660879fdd051f5ee92cd2936779ec57")
      );

      if (poolInfomation) {
        setPool2([
          {
            price: _prices ? _prices[1][0] / Math.pow(10, 30) : 0,
            pool: USDCSAmount,
            weight: (USDCSAmount / _totalUSDValuts) * 100,
            apr: aprToApy(poolInfomation[0].APR / 100).toFixed(2),
            totalStaked: poolInfomation[0].totalStaked,
            GDpriceToStakedToken: result ? result[2][0] / Math.pow(10, 18) : 0,
            withdrawable: poolInfomation[0].withdrawable,
            stakable: poolInfomation[0].stakable,
            vaultcap: poolInfomation[0].vaultcap,
            depositFee: poolInfomation[0].glpFees,
          },
          {
            price: _prices ? _prices[2][0] / Math.pow(10, 30) : 0,
            pool: ETHSAmount,
            weight: (ETHSAmount / _totalUSDValuts) * 100,
            apr: aprToApy(poolInfomation[1].APR / 100).toFixed(2),
            totalStaked: poolInfomation[1].totalStaked,
            GDpriceToStakedToken: result ? result[3][0] / Math.pow(10, 18) : 0,
            withdrawable: poolInfomation[1].withdrawable,
            stakable: poolInfomation[1].stakable,
            vaultcap: poolInfomation[1].vaultcap,
            depositFee: poolInfomation[1].glpFees,
          },
          {
            price: _prices ? _prices[3][0] / Math.pow(10, 30) : 0,
            pool: BTCSAmount,
            weight: (BTCSAmount / _totalUSDValuts) * 100,
            apr: aprToApy(poolInfomation[2].APR / 100).toFixed(2),
            totalStaked: poolInfomation[2].totalStaked,
            GDpriceToStakedToken: result ? result[4][0] / Math.pow(10, 18) : 0,
            withdrawable: poolInfomation[2].withdrawable,
            stakable: poolInfomation[2].stakable,
            vaultcap: poolInfomation[2].vaultcap,
            depositFee: poolInfomation[2].glpFees,
          },
          {
            price: _prices ? _prices[4][0] / Math.pow(10, 30) : 0,
            pool: BTCSAmount,
            weight: (BTCSAmount / _totalUSDValuts) * 100,
            apr: aprToApy(poolInfomation[3].APR / 100).toFixed(2),
            totalStaked: poolInfomation[3].totalStaked,
            GDpriceToStakedToken: result ? result[4][0] / Math.pow(10, 18) : 0,
            withdrawable: poolInfomation[3].withdrawable,
            stakable: poolInfomation[3].stakable,
            vaultcap: poolInfomation[3].vaultcap,
            depositFee: poolInfomation[3].glpFees,
          },
          {
            price: _prices ? _prices[4][0] / Math.pow(10, 30) : 0,
            pool: USDTAmount,
            weight: (USDTAmount / _totalUSDValuts) * 100,
            apr: aprToApy(poolInfomation[3].APR / 100).toFixed(2),
            totalStaked: poolInfomation[3].totalStaked * Math.pow(10, 12),
            GDpriceToStakedToken: result ? result[5][0] / Math.pow(10, 18) : 0,
            withdrawable: poolInfomation[3].withdrawable,
            stakable: poolInfomation[3].stakable,
            vaultcap: poolInfomation[3].vaultcap * Math.pow(10, 12),
            depositFee: poolInfomation[3].glpFees,
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const provider = new ethers.providers.JsonRpcProvider(
    "https://arb1.arbitrum.io/rpc"
  );

  // pool address
  const poolAddress = "0x7f9a20548d9482041dC33435A7Fb25Be7c4b98B9";

  const poolContract = new ethers.Contract(
    poolAddress,
    IUniswapV3Pool,
    provider
  );

  async function getPoolImmutables() {
    const immutables = {
      factory: await poolContract.factory(),
      token0: await poolContract.token0(),
      token1: await poolContract.token1(),
      fee: await poolContract.fee(),
      tickSpacing: await poolContract.tickSpacing(),
      maxLiquidityPerTick: await poolContract.maxLiquidityPerTick(),
    };
    return immutables;
  }

  async function getPoolState() {
    const slot = await poolContract.slot0();
    const PoolState = {
      liquidity: await poolContract.liquidity(),
      sqrtPriceX96: slot[0],
      tick: slot[1],
      observationIndex: slot[2],
      observationCardinality: slot[3],
      observationCardinalityNext: slot[4],
      feeProtocol: slot[5],
      unlocked: slot[6],
    };
    return PoolState;
  }

  async function fetchPrice() {
    const immutables = await getPoolImmutables();
    const state = await getPoolState();
    const DAI = new Token(42161, immutables.token0, 18, "GMD", "GMD Coin");
    const USDC = new Token(42161, immutables.token1, 6, "USDC", "USD Coin");
    console.log(immutables, state);

    //create a pool
    const DAI_USDC_POOL = new Pool(
      USDC,
      DAI,
      immutables.fee,
      state.sqrtPriceX96.toString(),
      state.liquidity.toString(),
      state.tick
    );
    const price1 = await DAI_USDC_POOL.token0Price;
    const price2 = await DAI_USDC_POOL.token1Price;
    console.log("Price", price1.toFixed(10), price2.toFixed(10));
    setPrice(Number(price1.toFixed(10)));
  }
  useEffect(() => {
    fetchData();
    fetchData2();
    fetchPrice();
    if (dataid) clearInterval(dataid);
    dataid = setInterval(() => {
      fetchData();
      fetchData2();
    }, 20000);
  }, []);

  return (
    <TokenInfoContext.Provider
      value={{
        totalUSDValuts,
        ethPrice,
        revenue,
        GLPinVault,
        GLPPrice,
        price,
        totalGmdStaked,
        fetchData,
        fetchPrice,
        tvl,
        fetchData2,
        pool2,
        GLPbackingNeeded2,
        GLPinVault2,
        totalESGmdStaked,
        v2ETHTVL,
        v2BTCTVL 
      }}
      children={children}
    />
  );
}