/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { useEffect } from "react";
import Hamburger from "./Hamburger";
import Button from "../Button";

function TopBar({ activePage, setActivePage, setNotification }) {
  useEffect(() => {
    const url = window.location.href;
    if (url.includes("dashboard")) setActivePage(1);
    if (url.includes("earn")) setActivePage(2);
  }, []);

  const menus = ["Dashboard", "BFR Vault","GMX Vault", "Stake"];
  const app_page = "https://app.gmdprotocol.com"
  const sm = useMediaQuery("(max-width : 660px)");
  return (
    <StyledContainer>
      <Box display={"flex"} alignItems={"center"}>
        <Logo>
          <Box />
          <Box>GMD</Box>
        </Logo>
        {!sm ? (
          <Menus active={activePage}>
            {menus.map((data, i) => {
              return (
                <a
                  key={i}
                  href={`${app_page}/${data.replace(" ", "").toLowerCase()}`}
                  onClick={() => setActivePage(i + 1)}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {data}
                </a>
              );
            })}
           <a
                 
                 href={`https://gmd-protocol.gitbook.io/gmd-protocol/`}
                 target ="blank"
               >
                 Docs
           </a>
           <a
                 
                 href={`https://stats.gmdprotocol.com`}
                 target ="blank"
               >
                 Stats
           </a>
           {/* <a
                
                href={`http://twitter.com/intent/tweet?text=This%20is%20my%20tweet%20to%20acquire%20early%20access%20to%20@GMDprotocol%20pseudo-delta-neutral%20USDC,ETH,and%20BTC%20vaults%20built%20on%20top%20of%20$GLP%20and%20mint%20$GMD,%20the%20protocol's%20governance%20and%20real%20yield%20token.%20visit%3A&url=http%3A%2F%2Fgmdprotocol.com%20for%20more%20information.`}
                target ="blank"
              >
                Early Whitelist Access
          </a> */}
          </Menus>      
        ) : (
          ""
        )}
 
      </Box>
      
      <Box display ={"flex"}>

        <Box mr={sm ? "30px" : 1 }>
            {sm ? (
              <Hamburger activePage={activePage} setActivePage={setActivePage} />
            ) : (
              ""
            )}
        </Box>

        <Box mr={sm ? "30px" : 0}>
          <a
            href={`${app_page}`}
            target={"_blank"}
            rel="noreferrer"
          >
            <Button type={"primary"} width={"98px"} height={"36px"}>
              Enter App
            </Button>
          </a>
          {sm ? (
            <Hamburger activePage={activePage} setActivePage={setActivePage} />
          ) : (
            ""
          )}
        </Box>


      </Box>
     
    </StyledContainer>
  );
}

const Menus = styled(Box)`
  display: flex;
  > a {
    padding: 18px 17px;
    display: block;
    color: #a0a3c4;
    font-size: 15px;
    line-height: 18px;
    font-weight: normal;
  }
  > a:nth-child(${({ active }) => active}) {
    color: white;
  }
`;

const Logo = styled(Box)`
  font-size: 24px;
  padding: 15px;
  margin-left: -15px;
  display: flex;
  margin-right: 12px;
  color: white;
  height: fit-content;
  align-items: center;
  > div:nth-child(1) {
    background-image: url("/icons/gmd.png");
    background-size: 100% 100%;
    width: 25px;
    height: 29px;
    margin-right: 8px;
  }
`;

const StyledContainer = styled(Box)`
  height: 62px;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
  background: transparent 0 0 no-repeat padding-box;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.062745098);
  position: relative;
  ::after {
    height: 1.3rem;
    width: 100%;
    content: " ";
    background: transparent
      linear-gradient(180deg, rgba(0, 0, 0, 0.1882352941), transparent) 0 0
      no-repeat padding-box;
    position: absolute;
    bottom: -1.3rem;
    left: 0;
  }
`;

export default TopBar;
