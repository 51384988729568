import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";

const StyledLogo = styled(Box)`
  font-size: 24px;
  padding: 15px;
  margin-left: -15px;
  display: flex;
  margin-right: 12px;
  //color: white;
  height: fit-content;
  align-items: center;

  > div:nth-child(1) {
    background-image: url("/icons/gmd.png");
    background-size: 100% 100%;
    //width: 25px;
    //height: 29px;
    margin-right: 15px;
    height: 47px;
    width: 40px;

  }
`;


const StyledTitle = styled(Typography)`
  color: var(--foreground-rgb);
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 36px !important;

`


const Logo = () => {
  return (
    <StyledLogo>
      <div />
      <StyledTitle>GMD</StyledTitle>
    </StyledLogo>
  )
}


export default Logo;