import { Box, Grid, Container, Typography } from "@mui/material";
import styled from "styled-components";
import { numberWithCommas } from "../../utils/functions";
import useTokenInfo from "../../hooks/useTokenInfo";
import { useEffect, useState } from "react";
import axios from "axios";


const StyledPanel = styled(Box)`
  max-width: 469px;
  /* width: fit-content; */
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  //margin: 16px 0;
  backdrop-filter: blur(4px);

  @media (max-width: 1360px) {
    margin-right: 30px;
    margin-bottom: 20px;
  }

  @media (max-width: 935px) {
    width: 469px !important;
  }

  @media (max-width: 850px) {
    margin-right: 0;
  }

  @media (max-width: 710px) {
    width: 350px;
  }

  > img {
    margin-left: 25.5px;
    margin-right: 39.5px;
    @media (max-width: 500px) {
      margin-right: 29.5px;
      margin-left: 0;
    }
    @media (max-width: 350px) {
      margin: 0 15px;
    }
  }
`;

const StyledTitle = styled.div`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 36px;
  /* identical to box height, or 200% */
  color: #5DCCFC;

`

const StyledSubtitle = styled.div`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 36px;
`

const StyledImage = styled.img`
  width: 55px;
  height: 55px;
`


const StatsSection = () => {

  const { GLPinVault, GLPbackingNeeded, ethPrice, GLPPrice, revenue, totalGmdStaked, price, GLPinVault2, GLPbackingNeeded2, totalESGmdStaked, v2ETHTVL, v2BTCTVL } =
    useTokenInfo();
  const [totalReserve, setTotalReserve] = useState(0);
  const [avaxTVL, setAvaxTVL] = useState(0);

  async function calcTotalReserve() {
    try {
      const response = await fetch("https://gnd-backend.vercel.app/getReserve");
      const data = await response.json();
      setTotalReserve(data.total_reserve);
      console.log(data.total_reserve, GLPPrice, GLPinVault2, GLPbackingNeeded2, totalReserve);
      console.log(GLPPrice * (GLPinVault2 - GLPbackingNeeded2) * Math.pow(10, 6) + totalReserve);
    } catch (error) {
      console.error("Error fetching TVL:", error);
    }
  }

  function getAvaxTVL() {
    axios.post('https://io.digitalauto.tech/gmd').then(res => setAvaxTVL(res.data)).catch(err => console.log(err));
  }
  
  useEffect(() => {
    calcTotalReserve();
    getAvaxTVL();
  }, []);

  const getBalanceUSD = (amount, price) => {
    return numberWithCommas((price * amount).toFixed(2));
  };
  console.log(v2ETHTVL, v2BTCTVL)
  const infos = [
    {
      title: "Total Value Locked",

      url: "/icons/dollar.svg",
      value: `$${numberWithCommas(
        (Number((GLPinVault * GLPPrice > 0
          ? GLPinVault * GLPPrice + totalGmdStaked * price + totalESGmdStaked * price + avaxTVL
          : 0
        )) + v2BTCTVL + v2ETHTVL).toFixed(2)
      )}`,
      number: GLPinVault * GLPPrice > 0
      ? GLPinVault * GLPPrice + totalGmdStaked * price + totalESGmdStaked * price + avaxTVL
      : 0, 
      width: 387,
    },
    {
      title: "Total Revenue Distributed",
      url: "/icons/lock.svg",
      value: `$${getBalanceUSD(revenue, ethPrice, 0)}`,
      number: getBalanceUSD(revenue, ethPrice, 0),
      width: 434,
    },
    {
      title: "Total Treasury Value",
      url: "/icons/hand-up.svg",
      value: `$${numberWithCommas(
        (
          GLPPrice * (GLPinVault2 - GLPbackingNeeded2) + totalReserve + 300000
        ).toFixed(2)
      )}`,
      number: GLPPrice * (GLPinVault2 - GLPbackingNeeded2) + totalReserve,
      width: 469,
    },
  ];

  return (
    // <Container sx={{paddingTop: 5}}>
    //     <Box>
    //         <Grid container
    //               justifyContent="space-between" spacing={{xs: 1, md: 1}} columns={{xs: 1, sm: 2, md: 3}}
    //               direction="row">
    //                 {infos.map((data, i) => {
    //                     return (
    //                         <Grid item xs={1} sm={1} md={1}>

    //                         <StyledPanel key={i}>
    //                             <StyledImage src={data.url} alt={""}/>
    //                             <Box>
    //                                 <StyledTitle>{data.title}</StyledTitle>
    //                                 <StyledSubtitle>{data.value}</StyledSubtitle>
    //                             </Box>
    //                         </StyledPanel>
    //                         </Grid>
    //                     );
    //                 })}
    //         </Grid>
    //     </Box>
    // </Container>
    <div className="tw-max-w-[1440px] tw-w-full tw-mx-auto tw-px-7 tw-flex tw-items-center tw-justify-between tw-flex-wrap laptop1:tw-justify-center">
      {infos.map((data, i) => {
        return (
          <StyledPanel key={i} sx={{ minWidth: 275, width: data.width }}>
            <StyledImage src={data.url} alt={""} />
            <Box>
              <StyledTitle>{data.title}</StyledTitle>
              <StyledSubtitle>{data.value/*.substring(0, data.value.length - 4)*/}</StyledSubtitle>
            </Box>
          </StyledPanel>
        );
      })}
    </div>
  )
}
export default StatsSection;
